export const journey = [
    {
      id: 0,
      img: 'https://mithilparmar-portfolio.s3.us-west-1.amazonaws.com/assets/images/infonikka.png',
      role: 'Software Engineer',
      company: 'Infonikka',
      date: 'May 2023 - Present',
      desc: 'System efficiency was enhanced through the development and maintenance of software applications. Critical project delivery processes were redesigned for timely completion by leading a cross-functional team. Web application performance was improved, and project delivery expedited through Agile methodologies. Testing procedures were streamlined with automated protocols, resulting in significant time savings.',
      skills: [
        'Git',
        'Agile',
        'Python',
        'Java',
        'Selenium',
      ],
      doc: '',
    },
    {
      id: 1,
      img: 'https://mithilparmar-portfolio.s3.us-west-1.amazonaws.com/assets/images/apexon.png',
      role: 'Data Engineer Intern',
      company: 'Apexon',
      date: 'Jun 2022 - Aug 2022',
      desc: 'Optimized SQL queries reduced query execution time by 10%, enhancing system performance. Redesigned data visualization with interactive Tableau dashboards facilitated efficient data-driven decision-making. Implemented TensorFlow and Keras for image classification, resulting in a 15% improvement in model accuracy.',
      skills: [
       'Tableau',
       'ETL',
       'Data Modelling',
       'SQL',
       'Python',
       'PySpark',
       'AWS Glue'
      ],
      doc: '',
    },
    {
      id: 2,
      img: 'https://mithilparmar-portfolio.s3.us-west-1.amazonaws.com/assets/images/sjsu.png',
      role: 'MS in Artificial Intelligence',
      company: 'San Jose State University',
      date: 'Aug 2021 - May 2023',
      desc: 'Coursework: Data Mining, Advanced Data Mining, Math Foundations for Decision and Data Sciences. Artificial Intelligence and Data Engineering, Machine Learning, Deep Learning, Natural Language Processing, AI Tools for System Engineering.',
      grades: [
        '3.61 GPA'
      ]
    },
    {
      id: 3,
      img: 'https://mithilparmar-portfolio.s3.us-west-1.amazonaws.com/assets/images/infonikka.png',
      role: 'Software Developer',
      company: 'Infonikka',
      date: 'Jan 2019 - Jun 2021',
      desc: 'Enhanced user engagement metrics through the development and maintenance of scalable web applications. Implemented codebase refactoring to improve code readability and maintainability. Utilized Agile methodologies to streamline project timelines and reduce cognitive load for the team. Conducted regular client meetings to gather feedback and nurture client relationships.',
      skills: [
        'Scaling',
        'Cassandra',
        'Python',
        'Angular',
        'React',
      ],
      doc: '',
    },
    {
      id: 4,
      img: 'https://mithilparmar-portfolio.s3.us-west-1.amazonaws.com/assets/images/abm_systems.png',
      role: 'Intern',
      company: 'ABM Systems, Inc.',
      date: 'Dec 2018 - Jan 2019',
      desc: 'Explored the applications of Machine Learning (ML), Human Machine Interface (HMI), and Artificial Intelligence (AI) in shaping the future of Smart Buildings, leveraging Alerton/Honeywell PLC/SCADA equipment.',
      skills: [
        'Machine Learning',
        'HMI',
        'Artificial Intelligence',
        'SCADA',
      ],
    },
    {
      id: 5,
      img: 'https://mithilparmar-portfolio.s3.us-west-1.amazonaws.com/assets/images/srm.png',
      role: 'B.Tech in Information Technology',
      company: 'SRM Institue of Science and Technology',
      date: 'Jun 2016 - May 2020',
      desc: 'Coursework: Program Design and Development, Data Structures, Object Oriented Programming, Algorithm Design and Analysis, Object Oriented Analysis and Design, Database Management Systems, Computer Networks, Programming in Java, Python Programming, Cloud Computing, Web Programming, Cryptogrphy, Human Computer Interaction.',
      grades: [
        '3.54 GPA'
      ],
      doc: '',
    },
  ];
  